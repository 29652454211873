import styles from './index.module.scss'

export default function Comment({comment}) {

    return (
        <div className={styles.Item}>
            <div className={styles.Ava}>
                {comment.NAME[0]}
            </div>
            <div className={styles.Name}>
                {comment.NAME}
            </div>
            <div className={styles.Date}>
                {comment.DATE}
            </div>
            <div className={styles.Text}>
                <div dangerouslySetInnerHTML={{__html: comment.COMMENT}}/>
            </div>
            {comment.ANSWER && (
                <div className={styles.Answer}>
                    <span>Ответ</span>
                    <div dangerouslySetInnerHTML={{__html: comment.ANSWER}}/>
                </div>
            )}
        </div>
    )
}